import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/main/auth/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthResponseModel } from '../auth.model';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    subscription: Subscription;

    /**
     * 
     * @param _matSnackBar 
     * @param _formBuilder 
     * @param _router 
     * @param _fuseConfigService 
     * @param _fuseProgressBarService 
     * @param _authService 
     */
    constructor(
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _authService: AuthService
    ) {
        this._fuseProgressBarService.setMode('indeterminate');

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            usuario: ['', [Validators.required]],
            contrasena: ['', Validators.required]
        });
    }

    onSubmitHandler(event): void {
        this._fuseProgressBarService.show();
        this._authService.login(
            this.loginForm.value.usuario,
            this.loginForm.value.contrasena)
            .then(
                (response: AuthResponseModel) => {
                    this._fuseProgressBarService.hide();
                    this._router.navigate(['/']);
                    if (!response.valido) {
                        this._matSnackBar.open(response.mensaje, 'OK', {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                }
            );
    }



    ngOnDestroy(): void {

    }
}
