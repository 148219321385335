import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {


    /**
     * 
     * @param {AuthService} _auth 
     */
    constructor(
        private _auth: AuthService
    ) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this._auth.token) {
            let authReq = req.clone({ headers: req.headers.append('JwtToken', this._auth.token) });           
            return next.handle(authReq);
        }
        return next.handle(req);
    }

}