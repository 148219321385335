import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Aplicaciones',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboards',
                icon: 'dashboard',
                type: 'item',
                url: '/apps/ventas/dashboard',
            },
            {
                id: 'ventas',
                title: 'Ventas',
                type: 'collapsable',
                icon: 'shopping_cart',
                children: [
                    {
                        id: 'ventas_por_sincronizar_listar',
                        title: 'Por sicronizar',
                        type: 'item',
                        url: '/apps/ventas/visitas/app',

                    },
                    {
                        id: 'ventas_visitas_listar',
                        title: 'Visitas',
                        type: 'item',
                        url: '/apps/ventas/visitas/sicom',
                    },
                    {
                        id: 'ventas_aplazamientos',
                        title: 'Aplazamientos',
                        type: 'item',
                        url: '/apps/ventas/aplazamiento',
                    },
                    {
                        id: 'ventas_adjuntos',
                        title: 'Adjuntos por enviar',
                        type: 'item',
                        url: '/apps/ventas/adjunto-por-enviar',
                    },
                    {
                        id: 'ventas_novedades_listar',
                        title: 'Novedades',
                        type: 'item',
                        url: '/apps/ventas/novedad',
                    },
                    {
                        id: 'ventas_reportes',
                        title: 'Reportes',
                        type: 'item',
                        url: '/apps/ventas/reporte',
                    },
                    {
                        id: 'ventas_mapa',
                        title: 'Mapa',
                        type: 'item',
                        url: '/apps/ventas/mapa',
                    },
                    {
                        id: 'constructora',
                        title: 'Constructoras',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'constructora_maestro',
                                title: 'Maestro Constructoras',
                                type: 'item',
                                url: '/apps/ventas/constructora/maestro/listar',
        
                            },
                            {
                                id: 'constructora_grabar_ventas',
                                title: 'Grabar Ventas',
                                type: 'item',
                                url: '/apps/ventas/constructora/grabar',
        
                            }
                        ]
                    },
                    {
                        id: 'ventas-oficina',
                        title: 'Ventas Oficina',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'ventas-oficina-visita-grabar',
                                title: 'Grabar Visitas (Pqr)',
                                type: 'item',
                                url: '/apps/ventas/oficina/grabarVisitas',        
                            },
                            {
                                id: 'ventas-oficina-visita-listar',
                                title: 'Lista Visitas Soluc ',
                                type: 'item',
                                url: '/apps/ventas/oficina/listarVisitas',        
                            },
                            {
                                id: 'ventas-oficina-grabar',
                                title: 'Grabar Ventas',
                                type: 'item',
                                url: '/apps/ventas/oficina/grabarVentas',        
                            }
                        ]
                    }, 
                    {
                        id : "ventas_anulaciones",
                        title: "Anulaciones de Ventas",
                        type: 'collapsable',
                        children : [
                            {
                                id: 'ventas_anulaciones_listar',
                                title: 'Lista Solicitudes',
                                type: 'item',
                                url: '/apps/ventas/anulaciones/listarSolicitudes',        
                            },
                            {
                                id: 'ventas_anulaciones_ver',
                                title: 'Grabar Solicitud Anulacion',
                                type: 'item',
                                url: '/apps/ventas/anulaciones/grabarSolicitud',        
                            },
                        ]

                    },
                    {
                        id: 'parametrizacion',
                        title: 'Parametrizar aprobaciones',
                        type: 'item',
                        url: '/apps/ventas/parametrizacion',

                    },
                ]
            }
        ]
    }
];
