import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_API_VENTAS, URL_API_LOCAL } from '../_config/constants';
import { IAsesor } from '../_models/interface';


@Injectable({
  providedIn: 'root'
})
export class AsesorService {

  /**
   * 
   * @param {HttpClient} _http 
   */
  constructor(
    private _http: HttpClient
  ) { }

  getAsesoresByCentro(codigo: string): Observable<any> {
    return this._http.get(`${URL_API_LOCAL}funcionarios/Centros/${codigo}/Asesores`).pipe(
      map(
        (res: Response) => {
          return res || [];
        }
      ));
  }

  getAsesoresByUsuario(identificacion: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this._http.get(`${URL_API_LOCAL}funcionarios/${identificacion}/Asesores`)
        .subscribe((response: any) => {
          resolve(response);
        }), reject;
    });
  }

  getAsesoresLocalStorage(centro: string): IAsesor[]{
    let asesores: IAsesor[] = [];   
    if (localStorage.getItem('asesores')){
      asesores = JSON.parse(localStorage.getItem('asesores'));     
    }        
    return asesores.filter(c => c.centro.codigo === centro);
    
  }

}
