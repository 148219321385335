import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginModule } from './login/login.module';
import { LockModule } from './lock/lock.module';
import { LogoutModule } from './logout/logout.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LoginModule,
    LogoutModule,
    LockModule
  ],
  exports: [
    LoginModule,
    LogoutModule,
    LockModule,
  ]
})
export class AuthModule { }
