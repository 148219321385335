import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { AuthUserModel, AuthResponseModel } from '../auth.model';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'lock',
    templateUrl: './lock.component.html',
    styleUrls: ['./lock.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LockComponent implements OnInit {

    lockForm: FormGroup;
    usuario: AuthUserModel;

    /**
     * 
     * @param _formBuilder 
     * @param _router 
     * @param _matSnackBar 
     * @param _fuseProgressBarService 
     * @param _fuseConfigService 
     * @param _authService 
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _matSnackBar: MatSnackBar,
        private _fuseProgressBarService: FuseProgressBarService,
        private _fuseConfigService: FuseConfigService,
        private _authService: AuthService

    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.usuario = this._authService.user;
        if (!this._authService.isLoggedIn) {
            this._router.navigate(['/auth/login']);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.lockForm = this._formBuilder.group({
            usuario: [
                {
                    value: this._authService.user.cedula,
                    disabled: true
                }, Validators.required
            ],
            contrasena: ['', Validators.required]
        });
    }

    onSubmitHandler(event): void {
        this._fuseProgressBarService.show();
        this._authService.login(
            this.lockForm.value.usuario,
            this.lockForm.value.contrasena)
            .then(
                (response: AuthResponseModel) => {
                    this._fuseProgressBarService.hide();
                    this._router.navigate(['/']);
                    if (!response.valido) {
                        this._matSnackBar.open(response.mensaje, 'OK', {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                }
            );
    }


}
