import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
      private _router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
          tap((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {

              }
          }, (err: any) => {
              console.log(err);
              if (err instanceof HttpErrorResponse) {
                  switch (err.status) {
                      case 401:
                        this._router.navigate(['/auth/lock']);
                        break;
                      case 404:
                          this._router.navigate(['/pages/errors/error-404']);
                          break;
                      default:
                          this._router.navigate(['/pages/errors/error-500']);
                          console.log('Error respuesta (' + err.status + '): ' + err.statusText);
                          break;
                  }
              }
          })
      );
  }
}