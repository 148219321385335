// local

//export const URL_API_VENTAS = 'http://localhost:53640/api/';   //PARA PROBAR LOCAL
//export const URL_API_VENTAS = 'https://localhost:44352/api/';   //PARA PROBAR LOCAL
//export const URL_API_LOCAL = 'http://localhost:49498/Api/';     
//export const URL_RENDER_ADJUNTO = 'https://localhost:44327/VisitaAdjuntos/Details/';


// pruebas 

//export const URL_API_VENTAS = 'http://172.16.1.112:8084/api/';    //PARA PROBAR LOCAL ANTIGUO
//export const URL_API_LOCAL = 'http://172.16.1.112/WebApi/Api/'; //PARA PROBAR LOCAL ANTIGUO
//export const URL_RENDER_ADJUNTO = 'http://172.16.1.112:8083/VisitaAdjuntos/Details/'; //PARA PROBAR LOCAL ANTIGUO

//export const URL_API_VENTAS = 'http://172.16.1.112:8085/Sicom/api/';    //PARA PROBAR LOCAL
//export const URL_API_LOCAL = 'http://172.16.1.112:8085/Backend/Api/';
//export const URL_RENDER_ADJUNTO = 'http://172.16.1.112:8083/VisitaAdjuntos/Details/'; //PARA PROBAR LOCAL


// produccion ALCANOS

//export const URL_API_VENTAS = 'http://172.16.1.107:8082/api/';
//export const URL_API_VENTAS = 'https://ventas.alcanosesp.com:3443/api/';
//export const URL_API_LOCAL = 'http://172.16.1.107/WebApi/Api/'; 
//export const URL_API_LOCAL = 'https://ventas.alcanosesp.com:6443/WebApi/Api/'; //WEB API
//export const URL_RENDER_ADJUNTO = 'http://172.16.1.107:8083/VisitaAdjuntos/Details/';
//export const URL_RENDER_ADJUNTO = 'https://ventas.alcanosesp.com:2443/VisitaAdjuntos/Details/'; // VISITA ADJUNTOS


// pruebas gases
 
export const URL_API_VENTAS = 'http://172.16.4.96:8084/api/';    //PARA PROBAR LOCAL
export const URL_API_LOCAL = 'http://172.16.4.96:8020/Api/';
export const URL_RENDER_ADJUNTO = 'http://172.16.4.96:8095/VisitaAdjuntos/Details/'; //PARA PROBAR LOCAL
 
//export const URL_API_VENTAS = 'https://ventasgoc.com.co:1443/api/';    //PARA PROBAR LOCAL
//export const URL_API_LOCAL = 'https://ventasgoc.com.co:3443/Api/';
//export const URL_RENDER_ADJUNTO = 'https://ventasgoc.com.co:2443/VisitaAdjuntos/Details/'; //PARA PROBAR LOCAL
 
// pruebas Metrogas
 
//export const URL_API_VENTAS = 'https://ventas.metrogasesp.com:4443/api/';    //PARA PROBAR LOCAL
//export const URL_API_LOCAL = 'https://ventas.metrogasesp.com:1443/Api/';
//export const URL_RENDER_ADJUNTO = 'https://ventas.metrogasesp.com:3443/VisitaAdjuntos/Details/'; //PARA PROBAR LOCAL


export const MY_FORMATS = {
    parse: {
      dateInput: 'YYYY-MM-DD'
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'YYYY-MM-DD',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },  
  };

  export const COMPLEMENTOS = [
    { valor: 'APTDO', descripcion : 'APARTADO'},
    { valor: 'AP', descripcion : 'APARTAMENTO'},
    { valor: 'AG', descripcion : 'AGENCIA'}, 
    { valor: 'ALM', descripcion : 'ALMACEN'},  
    { valor: 'AUT', descripcion : 'AUTOPISTA'},        
    { valor: 'BL', descripcion : 'BLOQUE'},
    { valor: 'BG', descripcion : 'BODEGA'},
    { valor: 'BLV', descripcion : 'BOULEVAR'},
    { valor: 'CS', descripcion : 'CASA'},
    { valor: 'CC', descripcion : 'CENTRO COMERCIAL'},
    { valor: 'CD', descripcion : 'CIUDADELA'},
    { valor: 'CONJ', descripcion : 'CONJUNTO'},
    { valor: 'CONS', descripcion : 'CONSULTORIO'},
    { valor: 'CORR', descripcion : 'CORREGIMIENTO'},
    { valor: 'ED', descripcion : 'EDIFICIO'},
    { valor: 'ET', descripcion : 'ETAPA'},
    { valor: 'FCA', descripcion : 'FINCA'},
    { valor: 'HC', descripcion : 'HACIENDA'},
    { valor: 'IN', descripcion : 'INTERIOR'},    
    { valor: 'LC', descripcion : 'LOCAL'},
    { valor: 'LT', descripcion : 'LOTE'},
    { valor: 'MZ', descripcion : 'MANZANA'},
    { valor: 'PISO', descripcion : 'PISO'},
    { valor: 'SEC', descripcion : 'SECTOR'},
    { valor: 'SMZ', descripcion : 'SUPERMANZANA'},
    { valor: 'TO', descripcion : 'TORRE'},
    { valor: 'UR', descripcion : 'UNIDAD RESIDENCIAL'},
    { valor: 'URB', descripcion : 'URBANIZACION'},
    { valor: 'VTE', descripcion : 'VARIANTE'},
    { valor: 'VDA', descripcion : 'VEREDA'},
    { valor: 'ZN', descripcion : 'ZONA'}    

  ];
