
export class AuthResponseModel {
  valido: boolean;
  mensaje: string;

  constructor(data?) {
    data = data || {};
    this.valido = data.valido || false;
    this.mensaje = data.mensaje || '';
  }
}


export class AuthUserModel {

  cedula: string;
  usuario: string;
  correo: string;
  nombres: string;
  apellidos: string;
  token: string;
  tipoAutenticacion: string;
  aplicaciones: {
    codigo: string,
    nombre: string,
    permisos: string[]
  }[];

  constructor(data?) {
    data = data || {};
    this.cedula = data.cedula || '';
    this.usuario = data.usuario || '';
    this.correo = data.correo || '';
    this.nombres = data.nombres || '';
    this.apellidos = data.apellidos || '';
    this.token = data.token || '';
    this.tipoAutenticacion = data.tipoAutenticacion || '';
    this.aplicaciones = data.aplicaciones || [];
  }

  get nombreCompleto(): string {
    return `${this.nombres} ${this.apellidos}`;
  }
}
