import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_API_VENTAS, URL_API_LOCAL } from '../_config/constants';

@Injectable({
    providedIn: 'root'
})
export class CentroService {
    
    /**
      * Constructor
      *
      * @param {HttpClient} _httpClient
      */
    constructor(private _http: HttpClient) { }


    getCentros(page?: number, limit?: number): Observable<any> {
        const paramsPage = page ? `&page=${page}` : '';
        const paramsLimit = limit ? `&limit=${limit}` : '';
        const url = URL_API_VENTAS + 'centroOperativos?abc=1'
            + paramsPage
            + paramsLimit;
        return this._http.get(url).pipe(map(
            (res: Response) => {
                const count = res['count'];
                const page = res['page'];
                const body = res['data'];
                return { count, page, body } || {};
            }
        ));
    }

    getCentrosByUsuario(identificacion: string): Promise<any> {
        if (localStorage.getItem('centros')) {
          const centros = JSON.parse(localStorage.getItem('centros'));
          return new Promise((resolve, reject) => {
                return of(resolve(centros)), reject;
          });
        } else {
            return new Promise((resolve, reject) => {
                return this._http.get(`${URL_API_LOCAL}funcionarios/${identificacion}/Centros`)
                    .subscribe((response: any) => {
                        resolve(response);
                    }), reject;
            });
        }        
    }


    getCentrosSicom(page?: number, limit?: number): Observable<any> {
        const url = URL_API_LOCAL + 'CenopeMaes';
        return this._http.get(url);
    }

    getMunicipiosCentro(idCentro: string): Observable<any>{
        const url = URL_API_LOCAL + 'CenopeMaes/' + idCentro + '/Municipios';
        return this._http.get(url);
    }

}
