import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private _router: Router,
    private _fuseProgressBarService: FuseProgressBarService,
    private _authService: AuthService
  ) {

    this._fuseProgressBarService.setMode('indeterminate');
  }

  ngOnInit(): void {
    this._fuseProgressBarService.show();
    this._authService.logout()
      .then(resp => {
        this._fuseProgressBarService.hide();
        this._router.navigate(['/']);
      });
  }

}
